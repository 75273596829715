import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { Hook, Nullable } from "sonobello.utilities.react";
import { useApi } from "sonobello.utilities.react.axios";

import { Customer } from "../../../../dtos/Customer";
import { ApiObjectError } from "../../../../dtos/ObxError";
import { HttpStatusCode } from "../../../../types/HttpStatusCodes";
import { ObxAxiosInstance } from "../../../../utils/UseObx";
import ApiRequestPaths from "../../../Constants/ApiRequestPaths";
import { TimeZoneStandardName } from "../../../Types/IBookingCenterOptionResponse";
import { IUsePostTokenError, SessionStart, UsePostTokenErrorType } from "../Components/LoadSessionView";

export interface ISessionStartResponse {
  /** The business unit with which the session is associated. */
  businessUnit: {
    id: string;
    name: string;
  };
  /** The customer associated with the token request. */
  customer: {
    id: string;
    firstName: string;
    lastName: string;
    opportunityId: string;
    phoneNumber: Nullable<string>;
    email: Nullable<string>;
    milesToCenter: Nullable<number>;
    postalCode: Nullable<string>;
    isQualified: boolean;
  };
  /** The center associated with the token request. */
  center: {
    /** The unique identifier of the center. */
    id: string;
    /** The plain text name of the center. */
    name: string;
    /** The plain text information of the center's address. */
    address: string;
    /** The phone number used to contact the center regarding appointment details or concerns. */
    phoneNumber: string;
    /** The name of the center's time zone in the 'TZ' format. */
    timeZoneName: TimeZoneStandardName;
  };
  /** The flow id associated with the token request. */
  flow: {
    /** The id of the flow associated with the token request. */
    id: string;
    /** The flow name associated with the token request. */
    name: string;
  };
  /** The lead id associated with the token request. */
  leadId: string;
  /** The token authorizing future requests for this session. */
  token: {
    /** The bearer token to be used to authorize all transactions with the OBX client server. */
    token: string;
    /** The ISO DateTime that the token will expire. */
    tokenExpires: string;
    /** The refresh token value used to refresh the auth token. */
    refresh: string;
    /** The ISO DateTime that the refresh token will expire. */
    refreshExpires: string;
  };
}

/** The object payload used to make a POST token request. */
export interface ISessionStartRequest {
  /** The key which identifies the session to be started. */
  sessionKey: string;
}

export class GetSessionStartError implements IUsePostTokenError {
  readonly type: UsePostTokenErrorType;
  readonly message?: string;

  constructor(errorResponse?: AxiosResponse<ApiObjectError<Customer>, unknown>) {
    if (errorResponse?.status === HttpStatusCode.Forbidden) this.type = UsePostTokenErrorType.AlreadyCompleted;
    else if (errorResponse?.status === HttpStatusCode.NotFound) this.type = UsePostTokenErrorType.NotFound;
    else this.type = UsePostTokenErrorType.SomethingWentWrong;
    this.message = errorResponse?.data?.description ?? errorResponse?.data?.message;
  }
}

const useGetSessionStart: Hook<SessionStart, IUsePostTokenError, string> = sessionKey => {
  const [result, setResult] = useState<Nullable<SessionStart>>(null);
  const { res, err, loading } = useApi<
    ISessionStartResponse,
    ISessionStartRequest,
    undefined,
    ApiObjectError<Customer>
  >({
    request: {
      method: "post",
      url: ApiRequestPaths.postSessionStart,
      payload: { sessionKey }
    },
    config: { axiosInstance: ObxAxiosInstance }
  });
  useEffect(() => res && setResult(new SessionStart({ ...res.payload, sessionKey })), [res]);

  return {
    result,
    error: (err && new GetSessionStartError(err.error.response)) || null,
    isLoading: loading
  };
};

export default useGetSessionStart;
