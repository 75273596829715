import { IANAZone } from "luxon";
import React, { useEffect } from "react";
import { Hook, IMutator, Nullable } from "sonobello.utilities.react";

import EnvironmentConfiguration from "../../../../constants/EnvironmentConfiguration";
import { EnhancedCustomer } from "../../../../dtos/Customer";
import { EnhancedToken } from "../../../../dtos/Token";
import LoadingExpander from "../../../App/Components/LoadingExpander";
import SupportedFlows from "../../../App/Compositions/SupportedFlows";
import {
  IForbiddenSessionViewRouter,
  IInvalidLinkViewRouter,
  ISomethingWentWrongRouter
} from "../../../Routing/Types/IRouter";
import { TimeZoneStandardName, TzTimezoneToZoneIdMap } from "../../../Types/IBookingCenterOptionResponse";
import ISession, { DefaultCenter, Session } from "../../../Types/ISession";

interface IInputParams {
  /** The business unit with which the session is associated. */
  businessUnit: {
    id: string;
    name: string;
  };
  /** The customer associated with the token request. */
  customer: {
    id: string;
    firstName: string;
    lastName: string;
    opportunityId: string;
    phoneNumber: Nullable<string>;
    email: Nullable<string>;
    milesToCenter: Nullable<number>;
    postalCode: Nullable<string>;
    isQualified: boolean;
  };
  /** The center associated with the token request. */
  center: {
    /** The unique identifier of the center. */
    id: string;
    /** The plain text name of the center. */
    name: string;
    /** The plain text information of the center's address. */
    address: string;
    /** The phone number used to contact the center regarding appointment details or concerns. */
    phoneNumber: string;
    /** The name of the center's time zone in the 'TZ' format. */
    timeZoneName: TimeZoneStandardName;
  };
  /** The flow id associated with the token request. */
  flow: {
    /** The id of the flow associated with the token request. */
    id: string;
    /** The flow name associated with the token request. */
    name: string;
  };
  /** The lead id associated with the token request. */
  leadId: string;
  /** The session key which identifies the session to be started. */
  sessionKey: string;
  /** The token authorizing future requests for this session. */
  token: {
    /** The bearer token to be used to authorize all transactions with the OBX client server. */
    token: string;
    /** The ISO DateTime that the token will expire. */
    tokenExpires: string;
    /** The refresh token value used to refresh the auth token. */
    refresh: string;
    /** The ISO DateTime that the refresh token will expire. */
    refreshExpires: string;
  };
}

/** A session constructed from the initial authorization response at the start of the session's lifecycle. */
export class SessionStart extends Session {
  constructor(inputParams: IInputParams) {
    const flowToCamelCase = `${inputParams.flow.name[0].toLowerCase()}${inputParams.flow.name.substring(1)}`;
    if (!SupportedFlows.some(f => f.name === flowToCamelCase)) throw "The specified flow is not supported.";

    const ianaZone = new IANAZone(TzTimezoneToZoneIdMap[inputParams.center.timeZoneName]);
    if (!ianaZone.isValid)
      throw `Failed to convert the 'TZ' time zone name '${inputParams.center.timeZoneName}' to an IANA time zone.`;

    super(
      new EnhancedCustomer({
        ...inputParams.customer,
        centerId: inputParams.center.id,
        opportunity: {
          id: inputParams.customer.opportunityId,
          campaign: { businessUnit: inputParams.businessUnit }
        },
        isPreviouslyQualified: inputParams.customer.isQualified,
        email: inputParams.customer.email || undefined,
        zipCode: inputParams.customer.postalCode || undefined,
        distanceToCenter: inputParams.customer.milesToCenter ?? undefined,
        phoneNumber: inputParams.customer.phoneNumber || undefined
      }),
      new DefaultCenter(
        inputParams.center.id,
        inputParams.center.name,
        inputParams.center.address,
        inputParams.center.phoneNumber,
        ianaZone
      ),
      inputParams.sessionKey,
      flowToCamelCase,
      false,
      inputParams.leadId,
      inputParams.flow.id,
      new EnhancedToken(inputParams.token),
      EnvironmentConfiguration.version,
      null,
      null,
      null
    );
  }
}

export enum UsePostTokenErrorType {
  NotFound,
  AlreadyCompleted,
  SomethingWentWrong
}

export interface ILoadSessionViewRouterProps {
  /** The key which uniquely identifies the session to the server. */
  readonly sessionKey: string;
}

export interface IUsePostTokenError {
  /** The error type which prevented the hook from succeeding. */
  readonly type: UsePostTokenErrorType;
  /** The optional message associated with the error. */
  readonly message?: string;
}

export interface ILoadSessionViewProps extends IMutator<ISession>, ILoadSessionViewRouterProps {
  /** The router which provides the routes for terminal and success scenarios. */
  router: IForbiddenSessionViewRouter & IInvalidLinkViewRouter & ISomethingWentWrongRouter;
}

export interface ILoadSessionViewConfig {
  /** The hook which obtains the token for the given session key. */
  useGetSessionStart: Hook<SessionStart, IUsePostTokenError, string>;
}

/** The temporary loading screen shown when loading a session from its key.
 * @remarks This page is most likely executed so quickly that the user won't often see it.
 */
const LoadSessionView: React.FC<ILoadSessionViewProps & ILoadSessionViewConfig> = ({
  router,
  sessionKey,
  useGetSessionStart,
  onChange
}) => {
  useEffect(() => localStorage.clear(), []);

  const { result, error } = useGetSessionStart(sessionKey);

  useEffect(() => {
    if (result) onChange(result);
  }, [result]);

  useEffect(() => {
    if (!error) return;
    if (error.type === UsePostTokenErrorType.AlreadyCompleted)
      router.goToForbiddenSessionView({ forbiddenSessionErrorMessage: error.message });
    else if (error.type === UsePostTokenErrorType.NotFound) router.goToInvalidLinkView();
    else router.goToSomethingWentWrongView();
  }, [error]);

  return <LoadingExpander />;
};

export default LoadSessionView;
