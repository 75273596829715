import EnvironmentConfiguration from "../../constants/EnvironmentConfiguration";

/** Utility class for checking supported centers */
class OpsUtilities {
  /** Returns `true` if the passed center id is supported by the OPS calendar process.
   * @param centerId - The center id to check.
   */
  public static isCenterSupported = (centerId: string): boolean =>
    EnvironmentConfiguration.optimizedPatientScheduler.notSupportedCenterIds.every(id => id !== centerId);
}

export default OpsUtilities;
